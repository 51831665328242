import * as fatchSet from "../../api/api";
import { InputItemBox } from "component/formItems";
import { Popup, SelPopup } from "component/popup";
import { PhoneSetting, tokenSave } from "js/function";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {BtnBox} from "../../component/btns";
import Header from "../../component/header";
import MemberTitle from "../../component/memberTitle";

const Join = (props) => {
    const param = useParams();
    const phone = param.phone;
    const terms = param.terms;

    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [btnChk,setBtnChk] =  useState(true);

    const [phoneType,setPhoneType] =  useState("");
    const [phoneTypeVal,setPhoneTypeVal] =  useState("");
    const [name,setName] =  useState("");
    const [birthF,setBirthF] =  useState("");
    const [birthB,setBirthB] =  useState("");
    const [certify,setCertify] =  useState("");
    const [responseSeq,setResponseSeq] =  useState("");

    const [certifyErr,setCertifyErr] =  useState("");

    const [timerCount,setTimerCount] =  useState("3:00");

    const [popupData,setPopupData] =  useState(null);

    const [selPopup,setSelPopup] =  useState(null);

    const [clickChk, setClickChk] = useState(true);

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                }
            }, 1000);
        }
    }

    function cetifiedStert(){
        if(clickChk){
            setClickChk(false);

            let formData = new FormData();
            formData.append("phone", phone);
            formData.append("name", name);
            formData.append("birth", birthF + birthB);
            formData.append("mobile_carrier", phoneTypeVal);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/join/certification`,
                success: (data) => {
                    setResponseSeq(data.data.response_seq);
                    setPage(1);
                    timerStart();

                    setClickChk(true);
                },
                err: (data) => {
                    setClickChk(true);

                    setPopupData({
                        addClass:null,
                        title:data.alert,
                        text:data.data||"",
                        btn0:"확인",
                        btn1:"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btnFunc1:(e)=>{}
                    });
                }
            })
        }
    }

    function cetifiedChk(){
        if (certify.length > 6) {
            let text = certify;
            setCertify(text.substr(0, 6));
        }
    }

    const [joinChk,setJoinChk] =  useState(true);
    function joinCom(){
        if(joinChk){
            setJoinChk(false);

            let joinData = new FormData();
            joinData.append("phone", phone);
            joinData.append("name", name);
            joinData.append("birth", birthF + birthB);
            joinData.append("mobile_carrier", phoneTypeVal);
            joinData.append("is_marketing", terms);
            joinData.append("response_seq", responseSeq);
            joinData.append("certification_code", certify);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: joinData,
                url: "/join",
                success: (joinComData) => {
                    clearInterval(sval.current);
                    sval.current = null;

                    setTimeout(() => {
                        setPopupData({
                            addClass:null,
                            title: "회원가입 완료",
                            text: "회원가입이 완료되었습니다.<br/>지금 바로 앨리펀을 이용해보세요.",
                            btn0:"확인",
                            btn1:"",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{
                                localStorage.setItem("token",joinComData.data.token.access_token);
                                localStorage.setItem("refresh_token",joinComData.data.token.refresh_token);
                                localStorage.setItem("user_id",joinComData.data.user_id);

                                tokenSave(joinComData.data.token.access_token);

                                props.logIn();

                                if(sessionStorage.getItem("setUrl")){
                                    let reUrl = sessionStorage.getItem("setUrl");
                                    sessionStorage.removeItem("setUrl");
                                    navigate(reUrl);
                                }else{
                                    navigate(`/main`);
                                }
                            },
                            btnFunc1:(e)=>{}
                        });
                    }, 100);
                },
                err: (data) => {
                    if(data.alert == "인증 번호가 맞지 않습니다."){
                        setCertifyErr("인증 번호가 올바르지 않습니다.");
                    }else if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert,
                            text:data.data ? data.data : "",
                            btn0:"확인",
                            btn1:"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btnFunc1:(e)=>{}
                        });
                    }

                    setTimeout(() => {
                        setJoinChk(true);
                    }, 200);
                }
            })
        }
    }

    function selItem(e){
        setPhoneTypeVal(e.val);
        setPhoneType(e.text);
    }

    useEffect(() => {
        if(page == 0){
            let regChk = /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]/g;
            if(phoneTypeVal !== "" && name !== "" && regChk.test(birthF + birthB)){
                setBtnChk(false);
            }else{
                setBtnChk(true);
            }
        }else if(page == 1){
            if(certify.length >= 6){
                setBtnChk(false);
            }else{
                setBtnChk(true);
            }
        }
    }, [phoneTypeVal, name, birthF, birthB, certify, page]);

    return (
        <div className="memberPage">
            <Header
                pageBack = {true}
                func={() => {page == 0 ? navigate(-1) : setPage(0)}}
            />
            <MemberTitle 
                title={page == 0 ? "앨리펀을 새롭게 이용하신다면<br/>본인인증이 필요해요" : "문자메세지로 발송된<br/>인증번호를 입력해주세요."}
                subTitle={page == 0 ? "본인인증은 회원가입 시, 단 1회만 진행됩니다." : `${PhoneSetting(phone)} 번호로 문자메세지를 발송드렸어요.`}
            />
            <div className="memberInput_section">
                {page == 0 ? <>
                    <InputItemBox
                        type="phone"
                        inputName="휴대폰번호"
                        placeholder="010-"
                        value={PhoneSetting(phone)}
                        disabled={true}
                    />
                    <InputItemBox
                        type="selItem"
                        inputName="통신사"
                        placeholder="통신사를 선택하세요"
                        value={phoneType}
                        readOnly={true}
                        func={(e) => {setPhoneType(e)}}
                        clickEv={(e) => {
                            setSelPopup({
                                title:"통신사",
                                addClass:null,
                                closeType:true,
                                items:["SKT", "KT", "LGU+", "알뜰폰(SKT)", "알뜰폰(KT)", "알뜰폰(LGU+)"],
                                val:[1,2,3,5,6,7],
                                sel:phoneType,
                                closeFunc:(e) => {},
                                func:(e) => {
                                    selItem(e);
                                }
                            })
                        }}
                    />
                    <InputItemBox
                        type="text"
                        inputName="이름"
                        placeholder="이름을 입력해주세요"
                        value={name}
                        max={10}
                        func={(e) => {setName(e)}}
                    />
                    <InputItemBox
                        type="registration"
                        inputName="주민등록번호 앞 7자리"
                        placeholder="생년월일 입력"
                        placeholder2="0"
                        value={birthF}
                        value2={birthB}
                        func={(e) => {setBirthF(e)}}
                        func2={(e) => {setBirthB(e)}}
                    />
                </> : <>
                    <InputItemBox
                        type="smsChk"
                        inputName="인증번호"
                        placeholder="인증번호 6자리를 입력해주세요"
                        readOnly={sval.current == null ? true : false}
                        timer={timerCount}
                        value={certify}
                        max={6}
                        clickEv={(e) => {cetifiedStert()}}
                        func={(e) => {setCertify(e);cetifiedChk()}}
                        errMsg={certifyErr == "" ? "" : certifyErr}
                    />
                </>}
            </div>
            <BtnBox
                text={page == 0 ? "본인인증" : "회원가입"}
                disabled={btnChk}
                func={page == 0 ? cetifiedStert : joinCom}
            />
            <Popup
                data={popupData}
            />
            <SelPopup
                data={selPopup}
            />
        </div>
    );
};

export default Join;