import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {FooterManu} from "component/footer";
import Header from "component/header";
import { BottomErrMsg, Popup, SelPopup } from "component/popup";
import ListBox from "component/listBox";
import { StoreList } from "component/list";
import Category from "component/category";
import useGet from "api/useGet";
import { storeLike } from "js/function";
import * as fatchSet from "../../api/api";

const Main = () => {
    const navigate = useNavigate();

    const [category,setCategory] = useState("");
    const [fixedCategory, setFixedCategory] = useState("");
    const fixedTargetRef = useRef(null);  
    
    const [selCloseType,setSelCloseType] = useState(true);

    const [mainAddr,setMainAddr] = useState("all");
    const [addr,setAddr] = useState("지역 전체");
    const [addrVal,setAddrVal] = useState("all");
    const [selPopup,setSelPopup] =  useState(null);
    const [selPopupOpenType,setSelPopupOpenType] =  useState(false);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [popupData,setPopupData] =  useState(null);

    const categoryList = useGet({
        url:"/category",
        loginType:"login"
    });

    const storeList = useGet({
        url:`/store?sub_location_id=${localStorage.getItem("sub_location_id") ? localStorage.getItem("sub_location_id") : ""}&category_id=${category}&can_gift=1${localStorage.getItem("token") ? "&user_id=" + localStorage.getItem("user_id") : ""}`,
        loginType:"login"
    });

    const mainLocationList = useGet({
        url:`/location`,
        loginType:"login"
    });

    const subLocationApi = useGet({
        url:`/location?parent_id=${mainAddr}`,
        loginType:"login",
        chk: mainAddr ? true : false
    });
    const subLocationList = subLocationApi?.data?.locations;

    function selOpen(){
        setSelPopupOpenType(true);
        setSelPopup({
            title:"지역 선택",
            addClass:null,
            closeType:selCloseType ? true : false,
            dataKey:"location_name",
            data:mainAddr !== "all" ? subLocationList : [{"id": "all","location_name": "지역 전체","created_at": "2023-05-03 00:00:00"}],
            sel:addr,
            closeFunc:(e) => {
                if(selCloseType){
                    setSelPopupOpenType(true)
                }
            },
            func:(e) => {
                selItem(e);
                setSelPopupOpenType(false);
            }
        })
    }

    function selItem(e){
        setAddrVal(e.val);
        setAddr(e.text);
        localStorage.setItem("main_location_id",mainAddr);
        localStorage.setItem("sub_location_id",e.val);
        localStorage.setItem("sub_location_text",e.text);
    }

    function handleScroll(){
        if(window.scrollY + 54 > fixedTargetRef.current.offsetTop){
            setFixedCategory("fixedItem");
        }else{
            setFixedCategory("");
        }
    };

    useEffect(() => {
        //방문자 수 집계
        let formData = new FormData();
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/visit",
            success: (data) => {},
            err: (data) => {}
        })

        if(localStorage.getItem("sub_location_id")){
            setAddrVal(localStorage.getItem("sub_location_id"));
        }else{
            setAddrVal("all");
        }
        if(localStorage.getItem("sub_location_text")){
            setAddr(localStorage.getItem("sub_location_text"));
        }else{
            setAddr("지역 전체");
        }

        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if(localStorage.getItem("main_location_id")){
            setMainAddr(localStorage.getItem("main_location_id"));
        }else{
            setMainAddr("all");
        }
        
        if(!localStorage.getItem("sub_location_id")){
            localStorage.setItem("main_location_id","all");
            localStorage.setItem("sub_location_id","all");
            localStorage.setItem("sub_location_text","지역 전체");
        }
    }, [mainLocationList]);

    useEffect(() => {
        if(selPopupOpenType){
            setSelPopup({
                title:"지역 선택",
                addClass:null,
                closeType:selCloseType ? true : false,
                dataKey:"location_name",
                data:mainAddr !== "all" ? subLocationList : [{"id": "all","location_name": "지역 전체","created_at": "2023-05-03 00:00:00"}],
                sel:addr,
                closeFunc:(e) => {
                    if(selCloseType){
                        setSelPopupOpenType(true);
                    }
                },
                func:(e) => {
                    selItem(e);
                    setSelPopupOpenType(false);
                }
            })
        }
    }, [subLocationList]);

    return (
        <div className="mainPage">
            <Header
                customHeaderItem={<div className="addrSelBox" onClick={(e) => {setSelCloseType(true);selOpen()}}>
                    <img src="/assets/images/basic/location.svg"/>
                    {addr}
                    <img src="/assets/images/basic/sel.svg"/>
                </div>}
                shLink={1}
            />
            <div className="category_section" ref={fixedTargetRef} style={{minHeight:"85.39px"}}>
                <ListBox type="horizontalScroll" addClass={fixedCategory}>
                    <Category allChk={1} active={category} data={categoryList.data?.categories} textVer={fixedCategory == "" ? 0 : 1} func={(selId) => setCategory(selId == "all" ? "" : selId)}/>
                </ListBox>
            </div>
            <div className="mainList_section">
                <StoreList data={storeList.data?.stores} storeLink={"/main/detail/"} like={(selId,type) => storeLike({
                    selId : selId,
                    type : type,
                    func : () => {
                        setBottomMsgData({
                            text : "관심매장이 추가되었습니다.",
                            chk : bottomMsgData.chk + 1
                        });
                    },
                    loginErr : () => {
                        setPopupData({
                            addClass:null,
                            title:"로그인이 필요합니다.",
                            text:"",
                            btn0:"취소",
                            btn1:"로그인",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btnFunc1:(e)=>{
                                let nowUrl = new URL(window.location.href);
                                sessionStorage.setItem("setUrl",nowUrl.pathname);
                                navigate("/member/login");
                            }
                        });
                    }
                })}/>
            </div>
            <FooterManu menuChk="0"/>
            <SelPopup
                data={selPopup}
            >
                <div className="horizontalScrollBox">
                    <ListBox type="horizontalScroll">
                        <Category allChk={0} active={mainAddr} data={mainLocationList?.data?.locations ? [{
                            "id": "all",
                            "location_name": "전체",
                            "created_at": "2023-05-03 00:00:00"
                        },...mainLocationList?.data?.locations] : []} textVer={1} func={(selId) => {setMainAddr(selId)}}/>
                    </ListBox>
                </div>
            </SelPopup>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default Main;